<template lang="pug">
.alert.alert-danger.text-center.mb-0.p-0
  .box-center.d-flex.justify-content-center
    .icon
      UilExclamationTriangle
    template(v-if="hasBraintreePaymentProblem")
      .d-flex.text.text-left
        span(v-html="$t(`paymentProblem.braintree.${braintreeProblemType}`)")
      .button
        om-button(primary @click="checkoutAction()") {{ $t('paymentProblem.button.updatePaymentMethod') }}
    template(v-else-if="hasBankTransferPaymentProblem")
      .d-flex.text.align-items-center
        span {{ $t('paymentProblem.bankTransfer.default') }}
      .button
        om-button(primary :loading="!isProformaDownloadable" @click="proformaAction()") {{ $t('paymentProblem.button.downloadProforma') }}
    template(v-else-if="hasShopifyPaymentProblem")
      .d-flex.text.align-items-center
        span(v-html="$t(`paymentProblem.shopify.default`)")
</template>
<script>
  import { createNamespacedHelpers } from 'vuex';
  import { getAccountIdFromCookie } from '@/util';
  import upgradePlanModal from '@/mixins/upgradePlanModal';
  import paymentProblem from '@/mixins/paymentProblem';
  import { UilExclamationTriangle } from '@iconscout/vue-unicons';

  const { mapActions: paymentActions, mapGetters: paymentGetters } =
    createNamespacedHelpers('payment');

  export default {
    components: {
      UilExclamationTriangle,
    },

    mixins: [upgradePlanModal, paymentProblem],

    data() {
      return {
        isProformaDownloadable: true,
      };
    },

    computed: {
      ...paymentGetters([
        'hasBraintreePaymentProblem',
        'hasBankTransferPaymentProblem',
        'hasShopifyPaymentProblem',
        'getSelectedPlan',
        'getBraintreePaymentErrorCode',
        'getLastOrderId',
      ]),

      braintreeProblemType() {
        return this.getBraintreePaymentProblemType(this.getBraintreePaymentErrorCode);
      },
    },
    methods: {
      ...paymentActions(['loadBilling', 'loadPlans', 'downloadProforma']),
      async checkoutAction() {
        await this.loadBilling();
        await this.loadPlans();
        this.navigateToCheckout(this.getSelectedPlan, getAccountIdFromCookie());
      },

      async proformaAction() {
        if (this.isProformaDownloadable === false) {
          return;
        }
        this.isProformaDownloadable = false;
        await this.downloadProforma(this.getLastOrderId);
        this.isProformaDownloadable = true;
      },
    },
  };
</script>
